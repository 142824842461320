import React, { useEffect, useContext } from 'react'

import { StateContext } from './state';
import http from '../../utils/server'
import { useHistory } from 'react-router-dom'
// import { Tabs } from 'antd';
// const { TabPane } = Tabs;

function Content(props) {
    let history = useHistory();
    const { state,dispatch } = useContext(StateContext)

    return (
        <div id='dataCon' style={{ margin: 5, backgroundColor:'#EBE9E7' , borderRadius:10,display:'flex',flexDirection:'column',overflow:'hidden',flex:1}}>
             {state.main.id !== undefined && props.children}
            {/* <Tabs defaultActiveKey="1" onChange={e=>{
                history.push(state.panes.filter(x=>x.key===e)[0].content);
            }}>
                {
                    state.panes.map(pane => (
                        <TabPane tab={pane.title} key={pane.key}>
                          {
                            //   pane.content
                              state.main.id !== undefined && props.children.filter(x=>x.key==pane.content)
                          }
                        </TabPane>
                      ))
                }
            </Tabs> */}
        </div>
        
    )
}


export default Content