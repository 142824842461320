import React, { createContext, useContext, useEffect, useState, useReducer } from 'react'
import { Table, Button, Input, Select, Modal, Upload, InputNumber, message, Tooltip } from 'antd'
import { StateContext } from '../../../state';
import http from '../../../../../utils/server'
import { ExclamationCircleOutlined,UploadOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { Mode } from '../../../../../App';
import smoke from '../../../../../../public/assets/Smoke.png'

function SelectInput(props) {
    return (
        <div style={{ display: 'flex' }}>
            <div style={{ ...props.style, padding: '0 11px', paddingTop: 4, backgroundColor: '#fafafa', border: '1px solid #d9d9d9', borderRight: '0px', borderRadius: 2 }}>{props.addonBefore}</div>
            <Select disabled={props.disabled} value={props.defaultValue} style={{ ...props.style, flex: 1 }} onChange={(value) => { props.onChange && props.onChange(value) }}>
                {props.option.map((item, index) => (
                    <Select.Option key={index} value={item[props.keyword]}>{item[props.text]}</Select.Option>
                ))}
            </Select>
        </div>
    )
}

const HMIContext = createContext();

function HmiNew() {
    const { actHmi, dispatchHmi } = useContext(HMIContext)
    const { state } = useContext(StateContext)
    return (
        <div>
            <Input style={{ marginBottom: 5 }} addonBefore="平面图名称" value={actHmi.name}
                onChange={(e) => { dispatchHmi({ type: 'update', hmi: { ...actHmi, name: e.target.value } }) }} />
            <SelectInput style={{ marginBottom: 5 }} addonBefore='所属区域' defaultValue={actHmi.district} option={state.district} keyword={"id"} text='name'
                onChange={value => { dispatchHmi({ type: 'update', hmi: { ...actHmi, district: value } }) }} />
             <Input style={{ marginBottom: 5 }} addonBefore="优先级" value={actHmi.index}
                onChange={(e) => { dispatchHmi({ type: 'update', hmi: { ...actHmi,  index: Number(e.target.value) } }) }} />
        </div>
    )
}

function HMIManage() {
    const { state, dispatch } = useContext(StateContext)
    const [actHmi, dispatchHmi] = useReducer((state, action) => {
        if (action.type === 'update') {
            return action.hmi
        } else if (action.type === 'clear') {
            return {
                name: "",
                layout: '',
                index: "",
                district: "",
                image:"",
            }
        }
    }, []);
    const [showNewModel, setShowNewModel] = useState(false)
    const [showFixModel, setShowFixModel] = useState(false)
    const[devices, setDevices] = useState(0);//区域内设备
    const[editVisb, setEditVisb] = useState(false);//编辑窗口状态
    const[xRelative, setXRelative] = useState(0);//添加测点的X坐标
    const[yRelative, setYRelative] = useState(0);//添加测点的Y坐标
    const[newVisb, setNewVisb] = useState(false);//添加测点弹窗
    const[editPointVisb, setEditPointVisb] = useState(false);//修改测点弹窗
    const[deviceInfo, setDeviceInfo] = useState({fontColor:'blue',fontSize:18});//当前测点的信息
    const[devicesInfo, setDevicesInfo] = useState([]);//平面图内所有测点的信息
    const[addMapEle, setAddMapEle] = useState('');//平面图背景区域元素
    const[isMouseDown, setIsMouseDown]= useState(false);//是否触发事件
    const[isMoving,setIsMoving] = useState(false);//是否开始移动
    const[moveEle,setMoveEle] = useState({});//移动的元素
    const[moveDevInfo,setMoveDevInfo] = useState({});//移动的元素
    function handleChange(info) {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            dispatchHmi({ type: 'update', hmi: {...actHmi,image:info.file.response.data.path} });
        }
    };
    useEffect(() => {
        http.get("/v1/layout", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'hmi', hmi: data.data })
            }
        })
        http.get("/v1/district", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'district', district: data.data })
            }
        })
        http.get("/v1/users", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'systemuser', systemuser: data.data.sort((a,b)=>a.name.localeCompare(b.name,'zh')) })
            }
        })
        http.get("/v1/device", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'device', device: data.data })
            } 
        })

    }, [])
    const columns = [
        {
            title: '平面图名称',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
        },
        {
            title: '所属区域',
            dataIndex: 'district',
            key: 'district',
            align: 'center',
            render: (text, record) => (
                <div>
                    {
                        state.district.filter(x => { return x.id === record.district }).length > 0 ? state.district.filter(x => { return x.id === record.district })[0].name : '无'
                    }
                </div>
            )
        },
        {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            align: 'right',
            width: '200px',
            render: (text, record) => (
                <div>
                    <Button size='small' type="primary" onClick={() => {
                        setEditVisb(true);

                        setDevices(state.device.filter(x=>x.district===record.district));
                        dispatchHmi({ type: 'update', hmi: record });
                    }}>
                        编辑
                    </Button>
                    <Button size='small' type="primary" style={{ marginLeft: '10px' }} onClick={() => {
                        setShowFixModel(true)
                        dispatchHmi({ type: 'update', hmi: record })
                    }}>
                        修改
                    </Button>
                    <Button size='small' type='primary' danger style={{ marginLeft: '10px' }} onClick={()=>{
                        Modal.confirm({
                            centered: true,
                            title: '确定要删除平面图吗？',
                            icon: <ExclamationCircleOutlined />,
                            content: '删除之后将不可恢复！！',
                            okText: '删除',
                            okType: 'danger',
                            cancelText: '取消',
                            onOk() {
                                http.delete("/v1/layout/"+record.id,{data:record}).then((data) => {
                                    http.get("/v1/layout", {}).then(data => {
                                        if (data.code === 0) {
                                            dispatch({ type: 'hmi', hmi: data.data })
                                        }
                                    })
                                })
                            },
                            onCancel() {
                                console.log('Cancel');
                            },
                        });
                         
                    }}>
                        删除
                    </Button>
                </div>
            ),
        },
    ]

    return (
        <div style={{height:'100%',padding:10,position:'relative',backgroundColor:'#fff'}}>
            <div style={{display:'flex',justifyContent:'space-between',marginBottom:10,border:'1px solid #ccc',borderRadius:5,padding:10}}>
                <div></div>
                <div style={{ float: 'right', marginBottom: 10 }}>
                    <Button style={{ backgroundColor: 'green', color: '#fff', borderRadius: 5 }}
                    onClick={() => {
                        setShowNewModel(true)
                        dispatchHmi({ type: 'clear', project_id: state.main.project_id })
                    }}>新建平面图</Button>
                </div>
            </div>
            
            <div style={{ position:'absolute',width:'100%',height:'100%',overflowY:'auto'}}>
                <Table bordered pagination={{showSizeChanger:true,hideOnSinglePage:true}}  rowKey={record => record.id} columns={columns} size='small' dataSource={state.hmi} 
                    scroll={{y:state.resolution.height<800?420:state.resolution.height<1000?550:700}}
                />
            </div>
            <Modal maskClosable={false} title="新增平面图" visible={showNewModel}
                destroyOnClose
                cancelText='取消'
                okText='确定'
                onOk={() => {
                    setShowNewModel(false)
                    dispatchHmi({ type: 'clear', project_id: state.main.project_id })
                    http.post("/v1/layout", actHmi).then((data) => {
                        http.get("/v1/layout", {}).then(data => {
                            if (data.code === 0) {
                                dispatch({ type: 'hmi', hmi: data.data })
                            }
                        })
                    })
                }}
                onCancel={() => {
                    setShowNewModel(false)
                    dispatchHmi({ type: 'clear', project_id: state.main.project_id })
                }}>
                <HMIContext.Provider value={{ actHmi, dispatchHmi }}>
                    <HmiNew />
                </HMIContext.Provider>
            </Modal>
            <Modal maskClosable={false} title="修改平面图" visible={showFixModel}
                destroyOnClose
                cancelText='取消'
                okText='确定'
                onOk={() => {
                    setShowFixModel(false)
                    dispatchHmi({ type: 'clear', project_id: state.main.project_id })
                    http.put("/v1/layout/" + actHmi.id, actHmi).then((data) => {
                        http.get("/v1/layout", {}).then(data => {
                            if (data.code === 0) {
                                dispatch({ type: 'hmi', hmi: data.data })
                            }
                        })
                    })
                }}
                onCancel={() => {
                    setShowFixModel(false)
                    dispatchHmi({ type: 'clear', project_id: state.main.project_id })
                }}>
                <HMIContext.Provider value={{ actHmi, dispatchHmi }}>
                    <HmiNew />
                </HMIContext.Provider>
            </Modal>
            <Modal maskClosable={false} title="编辑平面图" visible={editVisb} width={1000}
                destroyOnClose
                cancelText='取消'
                okText='确定'
                onOk={() => {
                    if(actHmi.name==='') return message.error('平面图名称为空');
                    http.put('/v1/layout/' + actHmi.id,{ ...actHmi, layout:JSON.stringify(devicesInfo)})
                    .then(data=>{
                        if(data.code===0&&data.msg==='OK'){
                            http.get("/v1/layout", {}).then(data => {
                                if (data.code === 0) {
                                    dispatch({ type: 'hmi', hmi: data.data })
                                } 
                            })
                        }
                    })
                    setAddMapEle('');setDeviceInfo({});setDevicesInfo([]);
                    setEditVisb(false);
                }}
                onCancel={() => {
                    setAddMapEle('');setDeviceInfo({});setDevicesInfo([]);
                    setEditVisb(false);
                }}>
                <div>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                        <span style={{width:300}}>平面图名称:
                            <Input style={{width:200}} value={actHmi.name}
                             onChange={(e) => { dispatchHmi({ type: 'update', hmi: { ...actHmi, name: e.target.value } }) }} />
                        </span>
                        <span>显示顺序: 
                            <InputNumber min={0} value={actHmi.index}
                                onChange={e => { dispatchHmi({ type: 'update', hmi: { ...actHmi,  index: e} }) }} />
                        </span>
                        {/* 上传平面图 */}
                        <Upload
                            name="avatar"
                            // listType="picture-card"
                            // className="avatar-uploader"
                            showUploadList={false}
                            headers={{ 'Authorization': `Bearer ${localStorage.getItem('token')}` }}
                            action= { Mode==='soc'?"/v1/upimage":'http://127.0.0.1:8086/v1/upimage'}
                            // beforeUpload={beforeUpload}
                            onChange={handleChange}
                        >
                            {
                                
                                <Button icon={<UploadOutlined />}>上传平面图</Button>
                            }
                        </Upload>
                        <Button onClick={()=>{
                            let img =  Mode==='soc'?actHmi.image:'http://127.0.0.1:8086/'+actHmi.image;
                            let imgObj = new Image();
                            imgObj.src = img;
                            setAddMapEle(imgObj);
                            setDevicesInfo(actHmi.layout!==undefined&&actHmi.layout!==''&&JSON.parse(actHmi.layout));
                        }} disabled={actHmi.layout===undefined||actHmi.layout===''}>显示测点</Button>
                    </div>
                    <div style={{height:'540px', width:'960px',marginTop:10, position:'relative',border:'1px solid #ccc',overflow:'scroll',padding:'0,0'}} id='view'
                         onContextMenu={e=>{
                            e.preventDefault();
                            if(addMapEle=="")return message.error('请先点击显示测点');
                            let tempEle= document.getElementById('view');
                            // setAddMapEle(tempEle);
                            let menuListEle= document.getElementById('menuList');
                            let tempEleX = tempEle.getBoundingClientRect().x;
                            let tempEleY = tempEle.getBoundingClientRect().y;
                            let xRelative = e.clientX-tempEleX+tempEle.scrollLeft-10;
                            let yRelative = e.clientY-tempEleY+tempEle.scrollTop-20;
                            setXRelative(xRelative);
                            setYRelative(yRelative);
                            let xPercent = xRelative/tempEle.scrollWidth;
                            let yPercent = yRelative/tempEle.scrollHeight;
                            setDeviceInfo({...deviceInfo,xPercent:xPercent,yPercent:yPercent});
                            menuListEle.style.display='block';
                        }}
                        onMouseMove={e=>{
                            let tempEle=document.getElementById('view');
                            let tClient = tempEle.getBoundingClientRect();
                            if(isMoving&&Object.keys(moveEle).length!==0&&addMapEle){
                                moveEle.style.top=e.clientY-tClient.top+tempEle.scrollTop-20+'px';
                                moveEle.style.left=e.clientX-tClient.left+tempEle.scrollLeft+'px';
                                moveDevInfo.xPercent=(e.clientX-tClient.left+tempEle.scrollLeft)/addMapEle.width;
                                moveDevInfo.yPercent=(e.clientY-tClient.top+tempEle.scrollTop-20)/addMapEle.height;
                            }
                            if(!isMoving){
                                if(devicesInfo.length!==0){
                                    let temp = devicesInfo.findIndex(x=>x.xPercent*addMapEle.width+tClient.x<e.clientX+tempEle.scrollLeft&&x.xPercent*addMapEle.width+tClient.x>e.clientX-60+tempEle.scrollLeft
                                        &&x.yPercent*addMapEle.height+tClient.y<e.clientY+tempEle.scrollTop&&x.yPercent*addMapEle.height+tClient.y>e.clientY+tempEle.scrollTop-60
                                        );
                                    setIsMouseDown(temp!==-1?true:false);
                                }
                            }
                        }}
                        // onMouseOver={e=>{
                        //     let tempEle=document.getElementById('view');
                        //     if(devicesInfo.length!==0){
                                
                        //         let temp = devicesInfo.findIndex(x=>x.xPercent*addMapEle.width+tempEle.x<e.clientX+tempEle.scrollLeft+30&&x.xPercent*addMapEle.width+tempEle.x>e.clientX-30+tempEle.scrollLeft
                        //             &&x.yPercent*addMapEle.height+tempEle.y<e.clientY+tempEle.scrollTop+30&&x.yPercent*addMapEle.height+tempEle.y>e.clientY+tempEle.scrollTop-30);
                        //         setIsMouseDown(temp!==-1?true:false);
                                
                        //     }
                        // }}
                        onMouseDown={e=>{
                            if(isMouseDown){
                                setIsMoving(true);
                                let tempEle=document.getElementById('view');
                                let tClient = tempEle.getBoundingClientRect();
                                let temp = devicesInfo.findIndex(x=>x.xPercent*addMapEle.width+tClient.x<e.clientX+tempEle.scrollLeft&&x.xPercent*addMapEle.width+tClient.x>e.clientX-60+tempEle.scrollLeft
                                    &&x.yPercent*addMapEle.height+tClient.y<e.clientY+tempEle.scrollTop&&x.yPercent*addMapEle.height+tClient.y>e.clientY+tempEle.scrollTop-60
                                    );
                                setMoveEle(temp!==-1?document.getElementById(`point${temp}`):{});
                                setMoveDevInfo(temp!==-1?devicesInfo[temp]:{});
                            }
                        }}
                        onMouseUp={e=>{
                            if(devicesInfo.length!==0){
                                let tempEle=document.getElementById('view');
                                let tClient = tempEle.getBoundingClientRect();
                                if(tClient.left<e.clientX&&tClient.right>e.clientX&&tClient.top<e.clientY&&tClient.bottom>e.clientY){
                                    setIsMoving(false);
                                }
                            }
                        }}
                        onClick={()=>{
                            let menuListEle= document.getElementById('menuList');
                            menuListEle.style.display='none';
                        }}
                    >
                        <div style={{position:'relative',padding:0,margin:0}}>
                        <img src={actHmi.image!==''&&actHmi.image!==undefined&& Mode==='soc'?actHmi.image:'http://127.0.0.1:8086/'+actHmi.image} alt="" srcset="" />
                        {
                            devicesInfo.length!==0&&
                            devicesInfo.map((item,index)=>{
                                let temp =state.device.filter(x=>x.id===item.devID).length>0?state.device.filter(x=>x.id===item.devID)[0]
                                :'';
                                return(
                                    <Tooltip title={temp!==''&&(temp.m_name+'-'+temp.name)}>
                                        {
                                            item.devSensorType===1?
                                            <div id={`point${index}`} style={{position:'absolute',left:item.xPercent*addMapEle.width,top:item.yPercent*addMapEle.height,width:80,height:60,display:'flex',alignItems:'center',flexDirection:'row',alignItems:'center',justifyContent:'space-between',zIndex:99}} key={10000+index}>
                                                <img style={{ width: 30, height: 30 }}src={ smoke } alt="" />
                                                {/* <div>
                                                    <div style={{color:item.fontColor,fontSize:item.fontSize}} >正常</div>
                                                </div> */}
                                            </div>
                                            :
                                            <div id={`point${index}`} style={{position:'absolute',left:item.xPercent*addMapEle.width,top:item.yPercent*addMapEle.height,width:80,height:60,display:'flex',alignItems:'center',flexDirection:'row',alignItems:'center',justifyContent:'space-between',zIndex:99}} key={index}>
                                                <Button type="text"  icon={<EnvironmentOutlined  style={{color:item.fontColor,fontSize:item.fontSize}}/> } /> 
                                                {/* <div>
                                                    <div style={{color:item.fontColor,fontSize:item.fontSize}} >25℃</div>
                                                    <div style={{color:item.fontColor,fontSize:item.fontSize}} >60%HF</div>
                                                </div> */}
                                            </div>
                                        }
                                        
                                    </Tooltip>
                                )
                            })
                        }
                        </div>
                        
                        <div style={{position:'absolute',left:xRelative+40,top:yRelative,width:50,height:50,display:'none'}} id="menuList">
                            <Button onClick={()=>{
                                setNewVisb(true)
                                let menuListEle= document.getElementById('menuList');
                                menuListEle.style.display='none';
                                setDeviceInfo({...deviceInfo,fontColor:'blue',fontSize:18})
                            }}>创建测点</Button>
                            <Button onClick={()=>{
                                setEditPointVisb(true);
                                let temp = devicesInfo.findIndex(x=>
                                    x.xPercent*addMapEle.width<xRelative+30&&x.xPercent*addMapEle.width>xRelative-30
                                    &&x.yPercent*addMapEle.height<yRelative+30&&x.yPercent*addMapEle.height>yRelative-30);
                                temp!==-1&&setDeviceInfo({...devicesInfo[temp],editId:temp});
                                let menuListEle= document.getElementById('menuList');
                                menuListEle.style.display='none';
                            }}>修改测点</Button>
                            <Button onClick={()=>{
                                let tempArrs = devicesInfo.concat();
                                let temp = devicesInfo.findIndex(x=>
                                    x.xPercent*addMapEle.width<xRelative+30&&x.xPercent*addMapEle.width>xRelative-30
                                    &&x.yPercent*addMapEle.height<yRelative+30&&x.yPercent*addMapEle.height>yRelative-30);
                                temp!==-1&&tempArrs.splice(temp,1);
                                setDevicesInfo(tempArrs);
                                let menuListEle= document.getElementById('menuList');
                                menuListEle.style.display='none';
                            }}>删除测点</Button>
                        </div>
                    </div>
                    
                </div>
            </Modal>
            <Modal title='创建测点' visible={newVisb} destroyOnClose
                onCancel={()=>setNewVisb(false)}
                onOk={()=>{
                    if(deviceInfo.devID===undefined) return message.error('没有选择测点');
                    setNewVisb(false);
                    let tempArr=devicesInfo;
                    tempArr.push(deviceInfo)
                    setDevicesInfo(tempArr);           
                }}
            >
                <div>
                    <div>
                        <span>显示颜色</span>
                        <Select style={{width:100,marginLeft:10}}
                        defaultValue='blue'
                        onChange={val=>setDeviceInfo({...deviceInfo,fontColor:val})}
                        >
                            <Select.Option key={1} value='black'>黑色</Select.Option>
                            <Select.Option key={2} value='white'>白色</Select.Option>
                            <Select.Option key={3} value='blue'>蓝色</Select.Option>
                            <Select.Option key={4} value='green'>绿色</Select.Option>
                            <Select.Option key={5} value='brown'>棕色</Select.Option>
                        </Select>
                    </div>
                    <div>
                        <span>字体大小</span>
                        <Select style={{width:100,marginLeft:10}}
                        defaultValue={18}
                        onChange={val=>setDeviceInfo({...deviceInfo,fontSize:val})}
                        >
                            <Select.Option key={1} value={10}>小</Select.Option>
                            <Select.Option key={2} value={14}>中</Select.Option>
                            <Select.Option key={3} value={18}>大</Select.Option>
                        </Select>
                    </div>
                    <div>
                        <span>选择测点</span>
                        <Select style={{width:300,marginLeft:10}}
                            onChange={val=>setDeviceInfo({...deviceInfo,devID:val*1})}
                        >
                            {
                                devices&&
                                devices.map(item=>(
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.m_name+'-'+item.name}
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    </div>
                    <div>
                        <span>测点类型</span>
                        <Select style={{width:300,marginLeft:10}}
                            defaultValue='0'
                            onChange={val=>setDeviceInfo({...deviceInfo,devSensorType:val})}
                        >
                            <Select.Option key={1} value='0'>传感器</Select.Option>
                            <Select.Option key={2} value='1'>烟感</Select.Option>
                        </Select>
                    </div>
                </div>
            </Modal>
            <Modal title='修改测点' visible={editPointVisb} 
                destroyOnClose
                onCancel={()=>setEditPointVisb(false)}
                onOk={()=>{
                    if(deviceInfo.devID===undefined) return message.error('没有选择测点');
                    setEditPointVisb(false);
                    let tempArr=devicesInfo.concat();
                    tempArr.splice(deviceInfo.editId,1);
                    tempArr.push(deviceInfo)
                    setDevicesInfo(tempArr);                   
                }}
            >
                <div>
                    <div>
                    <span>显示颜色</span>
                    <Select style={{width:100,marginLeft:10}}
                       value={deviceInfo.fontColor}
                       onChange={val=>setDeviceInfo({...deviceInfo,fontColor:val})}
                    >
                        <Select.Option key={1} value='black'>黑色</Select.Option>
                        <Select.Option key={2} value='white'>白色</Select.Option>
                        <Select.Option key={3} value='blue'>蓝色</Select.Option>
                        <Select.Option key={4} value='green'>绿色</Select.Option>
                        <Select.Option key={5} value='brown'>棕色</Select.Option>
                    </Select>
                    </div>
                    <div>
                    <span>字体大小</span>
                    <Select style={{width:100,marginLeft:10}}
                        value={deviceInfo.fontSize}
                       onChange={val=>setDeviceInfo({...deviceInfo,fontSize:val})}
                    >
                        <Select.Option key={1} value={10}>小</Select.Option>
                        <Select.Option key={2} value={14}>中</Select.Option>
                        <Select.Option key={3} value={18}>大</Select.Option>
                    </Select>
                    </div>
                    <span>选择测点</span>
                    <Select style={{width:300,marginLeft:10}}
                        value={deviceInfo.devID}
                        onChange={val=>setDeviceInfo({...deviceInfo,devID:val*1})}
                    >
                        {
                            devices&&
                            devices.map(item=>(
                                <Select.Option key={item.id} value={item.id}>
                                    {item.m_name+'-'+item.name}
                                </Select.Option>
                            ))
                        }
                    </Select>
                    <div>
                        <span>测点类型</span>
                        <Select style={{width:300,marginLeft:10}}
                            value={deviceInfo.devSensorType===1?'烟感':'传感器'}
                            onChange={val=>setDeviceInfo({...deviceInfo,devSensorType:val*1})}
                        >
                            <Select.Option key={1} value='0'>传感器</Select.Option>
                            <Select.Option key={2} value='1'>烟感</Select.Option>
                        </Select>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default HMIManage