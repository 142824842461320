import {useContext,useEffect, useState} from 'react'
import Sider from './sider'
import Header from './header'
import Content from './content'
import { ConfigProvider ,Modal,Space,Input} from 'antd'
import{StateContext} from './state'
import zhCN from 'antd/lib/locale/zh_CN';
import { Mode } from '../../App'
import moment from 'moment'
import 'moment/locale/zh-cn'
import { useHistory } from 'react-router-dom'
import http from '../../utils/server'
import md5 from 'js-md5';
moment.locale('zh-cn')

function formatDateTimes(inputTime) {
    var date = new Date(inputTime);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    var minute = date.getMinutes();
    var second = date.getSeconds();
    minute = minute < 10 ? ('0' + minute) : minute;
    second = second < 10 ? ('0' + second) : second;
    return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
  };
function Mnanger(props) {
    const { state, dispatch } = useContext(StateContext)
    const [pwdReg,setPwdReg] = useState('[\\w\\W]{2,}');
    const [mainTodo,setMainTodo] = useState('');
    const [repw, setrepw] = useState(false);
    const [npwo, setnpwo] = useState("");
    const [npw1, setnpw1] = useState("");
    const [npw2, setnpw2] = useState("");
    const [msg, setmsg] = useState("");
    const [msg1, setmsg1] = useState("");
    const [user,setUser] = useState(null)
    let history = useHistory()
    useEffect(()=>{
        const getResolution = () => {
            const width = window.screen.width;
            const height = window.screen.height;
            dispatch({ type: 'resolution', resolution: {width,height}})
          };
          const handleMouseClick=()=>{
            dispatch({type:'runningtime',runningtime:0})
          }
          getResolution();
          window.addEventListener('resize', getResolution);
          window.addEventListener('click', handleMouseClick);
           http.get("/v1/main", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'main', main: data.data })
                setUser(data.data)
                let todo = data.data.todo.trim();
                setMainTodo(todo);
                let todo_Con =todo===''?null:JSON.parse(todo) ;
                if(todo_Con){
                    if(todo_Con.isLock){
                        dispatch({type:'runningtime',runningtime:0})
                        dispatch({type:'runningtimeCount',runningtimeCount:todo_Con.lockTimer?todo_Con.lockTimer:0})
                    }else{
                        dispatch({type:'runningtime',runningtime:0})
                        dispatch({type:'runningtimeCount',runningtimeCount:0})
                    }
                    let pwdChange = {isChange:false,start:'2024-01-01 00:00:00',timer:30}
                    if(todo_Con.isPwdChange){
                        pwdChange.isChange = true;
                        pwdChange.start = todo_Con.pwdStart;
                        pwdChange.timer = todo_Con.pwdTimer; 
                    }
                    dispatch({type:'pwdChange',pwdChange:pwdChange})
                    if(todo_Con.isPwdReg){
                        let len = todo_Con.pwdLen?todo_Con.pwdLen:2;
                        let reg = `^((?=.*[a-z])|(?=.*[A-Z]))(?=.*\\d)[\\w\\W]{${len},}`
                        if(todo_Con.isIncludeLetterS){
                            reg = `^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[\\w\\W]{${len},}`
                        }
                        if(todo_Con.isIncludeSymbol){
                            reg =  `^((?=.*[a-z])|(?=.*[A-Z]))(?=.*\\d)(?=.*[\\W_])[\\w\\W]{${len},}`
                        }if(todo_Con.isIncludeLetterS&&todo_Con.isIncludeSymbol){
                            reg = `^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[\\W_])[\\w\\W]{${len},}`
                        }
                        setPwdReg(reg);
                    }
                }
                // dispatch({ type: 'runningtimeCount', runningtimeCount:  })
            } 
        })
    },[])
    useEffect(() => {
        let runningtime = state.runningtime;
        let runningtimeCount = state.runningtimeCount;
        
        if (runningtimeCount>0){
            if(runningtime<runningtimeCount){
                let timer = setInterval(() => {
                    runningtime++;
                    dispatch({type:'runningtime',runningtime:runningtime})
                }, 60*1000);
                return () => clearInterval(timer);
            }else{
                dispatch({type:'runningtime',runningtime:0})
                history.push('/login')
            }
        }
    }, [state.runningtime,state.runningtimeCount])

    useEffect(() => {
        let pwdChange = state.pwdChange;
        if (pwdChange&&pwdChange.isChange){
            let timer = setInterval(() => {
                let t =(new Date().getTime()-new Date(pwdChange.start).getTime())/1000/60/60/24;
                if(t>pwdChange.timer){
                    setrepw(true)
                }
                else if(t>((pwdChange.timer-7)>0?pwdChange.timer-7:1)){
                    Modal.warning({
                        title:`密码到期提醒`,
                        content: `密码即将到期，请及时修改`,
                    });
                } 
            }, 60*1000);
            return () => clearInterval(timer);
        }
    }, [state.pwdChange])
    useEffect(()=>{
        let todo = state.mainTodo.trim();
        setMainTodo(todo);
        let todoCon = todo===''?null:JSON.parse(todo)
        if(todoCon&&todoCon.isPwdReg){
            let len = todoCon.pwdLen?todoCon.pwdLen:2;
            let reg = `^((?=.*[a-z])|(?=.*[A-Z]))(?=.*\\d)[\\w\\W]{${len},}`
            if(todoCon.isIncludeLetterS){
                reg = `^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[\\w\\W]{${len},}`
            }
            if(todoCon.isIncludeSymbol){
                reg =  `^((?=.*[a-z])|(?=.*[A-Z]))(?=.*\\d)(?=.*[\\W_])[\\w\\W]{${len},}`
            }if(todoCon.isIncludeLetterS&&todoCon.isIncludeSymbol){
                reg = `^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[\\W_])[\\w\\W]{${len},}`
            }
            setPwdReg(reg);
        }
    },[state.mainTodo])
    return (
        <ConfigProvider locale={zhCN}>
            {
                Mode === 'soc'?
                <div style={{ display: 'flex', height: '100vh' ,backgroundColor:'#000C17',width:'100%',overflow:'hidden'}}>
                    <Sider />
                    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column',backgroundColor:"#E4E5E6",flexGrow:1 }}>
                        <Header user={user} ></Header>
                        <Content >{props.children}</Content>
                    </div>
                </div>
                :
                <div style={{ display: 'flex', height: '100vh',flexDirection: 'column',flexGrow:1,backgroundColor:'#E4E5E6'}}>
                    <Header local user={user}/>
                    <Content >{props.children}</Content>
                </div>
            }
            <Modal maskClosable={false} title="修改密码"
                visible={repw}
                destroyOnClose
                closable={false} cancelText='取消'
                okText='确定'
                onOk={() => {
                    // let regex =(myConf&&myConf.isPwdL)? /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[\w\W]{8,}$/:/[\w\W]{2,}/;
                    let regex = new RegExp(pwdReg);
                    (regex.test(npw1)&&npw1 === npw2 && npw1.length > 2) &&
                        http.post("/v1/userspassword", { who: state.main.account, password: md5(npwo), account: state.main.account, newpassword: md5(npw1) }).then(data => {
                            if (data.code === 0) {
                                let todo = mainTodo.trim();
                                let todoCon = todo===''?null:JSON.parse(todo)
                                if(todoCon.isPwdChange){
                                    let stime = formatDateTimes(new Date())
                                    todoCon={...todoCon,pwdStart:stime}
                                    let pwdChange = state.pwdChange;
                                    pwdChange={...pwdChange,timer:stime}
                                    dispatch({type:'pwdChange',pwdChange:pwdChange})
                                    dispatch({type:'mainTodo',mainTodo:todo})
                                }
                                Modal.success({
                                    title: "成功",
                                    content: "密码修改成功,请重新登录",
                                    onOk() { history.push('/login') },
                                });
                            } else {
                                Modal.warning({
                                    title: data.code,
                                    content: data.msg,
                                });
                            }
                        })

                }}
                cancelButtonProps={{
                    disabled: true,
                  }}
                >
                <Space direction="vertical">
                    <Input.Password addonBefore="输入旧密码" placeholder="input password" value={npwo}
                        onChange={(e) => {
                            setnpwo(e.target.value)

                        }} />
                        <font size="3" > </font>
                    <Input.Password addonBefore="输入新密码" placeholder="input password" value={npw1}
                        onChange={(e) => {
                            let ss = e.target.value
                            // let regex =(myConf&&myConf.isPwdL)? /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[\w\W]{8,}$/:/[\w\W]{2,}/;
                            let regex = new RegExp(pwdReg);
                            if(!regex.test(ss)){
                                let todo = mainTodo.trim();
                                let todoCon = todo===''?null:JSON.parse(todo)
                                let msg = '密码必须为2位以上'
                                if(todoCon&&todoCon.isPwdReg){
                                    let len = todoCon.pwdLen?todoCon.pwdLen:2;
                                    msg = `密码必须为${len}位以上,包含数字和字母`;
                                    if(todoCon.isIncludeLetterS){
                                        msg = `密码必须为${len}位以上,包含数字和大小写字母`;
                                    }
                                    if(todoCon.isIncludeSymbol){
                                        msg = `密码必须为${len}位以上,包含数字、字母、特殊符号`;
                                    }if(todoCon.isIncludeLetterS&&todoCon.isIncludeSymbol){
                                        msg = `密码必须为${len}位以上,包含数字、大小写字母、特殊符号`;
                                    }
                                }
                                setmsg(msg);
                                ss='';
                                // setmsg((myConf&&myConf.isPwdL)?"密码长度必须为8位以上,必须包含大小字母、数字、特殊字符":"密码必须为2位以上");
                                // ss='';
                            }else{
                                setmsg("");
                                (npw2!==""&&ss != npw2 )? setmsg1("两次输入的密码不相同") : setmsg1("")
                            }
                            setnpw1(e.target.value)
                            
                        }} />
                        <font size="3" color="red">{msg}</font>
                    <Input.Password addonBefore="重输新密码" placeholder="input password" value={npw2}
                        onChange={(e) => {
                            let ss = e.target.value
                            ss !== npw1 ? setmsg1("两次输入的密码不相同") : setmsg1("")
                            setnpw2(e.target.value)

                        }} />
                    <font size="3" color="red">{msg1}</font>
                </Space>
            </Modal>
        </ConfigProvider>
    )
    
}
export default Mnanger;